import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { apiGetSession } from '../../lib/api';
import AdminSite from '../../admin/Site';
import GuestSite from '../../guest/Site';
import LoginSite from '../../login/Site';

export function CertFunnel(props) {
	const app = useContext(AppContext);
	const [session, setSession] = useState(null);
	app.session = session; app.setSession = setSession;
	useEffect(() => apiGetSession(app, success => setSession(success)), [app]);
	if (session === null) return <></>;
	if (session.type < 1) return <LoginSite />;
	if (session.type > 1) return <AdminSite />;
	return <GuestSite />;
}